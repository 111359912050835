<template>
  <div class="conocenos-c">
    <Navigator />
    <div class="con-c">
      <div class="header-c-c"></div>
      <div v-html="about.aboutDescription" class="texto__about">

      </div>
      <!-- <p class="con-p">
        Amamos la comida, lo que sucede y existe alrededor de ella, buscamos
        atender el sentido de la intuición, esa corazonada que nos dice que
        estamos ante algo nuevo, algo delicioso, algo que nos hará felices y que
        queremos compartir contigo.
      </p>
      <p class="con-p">¡Hablemos de comida!</p> -->
      <div class="people-con mtb">
        <img src="../assets/i1.png" alt="people" class="people-img" />
        <p class="people-name">MICAMEN HAUFON</p>
        <p class="people-t">Dirección General</p>
        <a href="mailto:micamen@revistafoodie.com" class="people-mail">micamen@revistafoodie.com</a>
      </div>

      <div class="collabs-c">
        <div class="people-con">
          <img src="../assets/i2.png" alt="people" class="people-img" />
          <p class="people-name">RENÉE PERALTA</p>
          <p class="people-t">Dirección Creativa</p>
          <a href="mailto:renee@revistafoodie.com" class="people-mail">renee@revistafoodie.com</a>
        </div>
        <div class="people-con">
          <img src="../assets/i3.png" alt="people" class="people-img" />
          <p class="people-name">JOSÉ PERALTA</p>
          <p class="people-t">Editor de Contenidos</p>
          <a href="mailto:pepe@revistafoodie.com" class="people-mail">pepe@revistafoodie.com</a>
        </div>
        <div class="people-con">
          <img src="../assets/i4.png" alt="people" class="people-img" />
          <p class="people-name">ENRIQUE BAN SÁNCHEZ</p>
          <p class="people-t">Editor de Contenidos</p>
          <a href="mailto:enrique@revistafoodie.com" class="people-mail">enrique@revistafoodie.com</a>
        </div>
        <div class="people-con">
          <img src="../assets/i5.png" alt="people" class="people-img" />
          <p class="people-name">BÁRBARA HUIPE</p>
          <p class="people-t">Editor de Contenidos</p>
          <a href="mailto:barbara@revistafoodie.com" class="people-mail">barbara@revistafoodie.com</a>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Navigator from "../components/items-page/Navigator";
import dayjs from "dayjs";
let urlpath = require("../global/url");
import Footer from "../components/items-page/Footer";

export default {
  components: {
    Navigator,
    Footer,
  },

  async created() {
    await this.getAllInfoAbt("about");
  },

  computed: {
    about() {
      const aboutAll = this.$store.getters["about/data"][0];
      if(!aboutAll) return '';
      return aboutAll;
    },
  },

  methods: {
    ...mapActions('about', ['setAddedAbt', 'getAllInfoAbt']),
  },
};
</script>
<style>
.header-c-c {
  width: 100%;
  height: 38.217vw;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../assets/header.png");
  margin-bottom: 24.155vw;
}

.con-c {
  padding-bottom: 24.155vw;
}

.con-c p {
  font-size: 5vw;
  line-height: 5vw;
  font-weight: 600;
  text-align: center;
  margin: auto 1vw 4.831vw;
}

.people-con {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 6.831vw;
}

.people-con.mtb {
  padding: 24vw 0 54vw;
}

.people-con .people-img {
  width: 78.502vw;
  margin-bottom: 12.039vw;
}

.people-con .people-name {
  padding: 9.662vw 0;
  width: 86.957vw;
  background: black;
  color: white;
  margin: auto;
}

.people-con .people-t {
  margin: 4.831vw auto 0;
}

.people-con .people-mail {
  font-size: 5vw;
  line-height: 5vw;
  color: black;
  text-align: center;
}

.collabs-c .people-con .people-name {
  border: 1vw solid black;
  background: white;
  color: black;
  width: 71.618vw;
  padding: 4.831vw 0;
}

@media (min-width: 768px) {
  .header-c-c {
    width: 100%;
    height: 12.74vw;
    margin-bottom: 5.208vw;
  }

  .con-c {
    padding-bottom: 5.208vw;
  }

  .con-c p {
    font-size: 1.4vw;
    line-height: 1.6vw;
    margin: auto auto 1.831vw;
  }

  .con-c .con-p {
    width: 60vw;
  }

  .texto__about {
    width: 60vw;
    margin: 0 auto;
  }

  .collabs-c {
    display: flex;
    justify-content: center;
  }

  .people-con {
    margin: 0 2vw 6.831vw;
  }

  .people-con .people-img {
    width: 27.344vw;
    margin-bottom: 3.039vw;
  }

  .people-con.mtb {
    padding: 6vw 0 12vw;
    margin: 0;
  }

  .people-con .people-name {
    padding: 2.083vw 0;
    width: 22.396vw;
  }

  .people-con .people-t {
    margin: 2.831vw auto 0.6vw;
  }

  .people-con .people-mail {
    font-size: 1vw;
    line-height: 1.3vw;
  }

  .collabs-c .people-con .people-name {
    border: 0.3vw solid black;
    background: white;
    color: black;
    width: 17.833vw;
    padding: 2.083vw 0;
  }

  .collabs-c .people-con .people-img {
    width: 16.927vw;
    margin-bottom: 3.039vw;
  }
}
</style>